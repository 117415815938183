import '../styles/global.scss'
import 'tailwindcss/tailwind.css'

import Script from 'next/script'

function MyApp({ Component, pageProps }) {  
  return <>
    <Script id="gtm" strategy="afterInteractive">
      {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-K6QSPH7');`}
    </Script>
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-K6QSPH7" height="0" width="0" style={{display:"none", visibility:"hidden"}}></iframe></noscript>
    <Component {...pageProps} />
  </>
}
  
export default MyApp

